import { render, staticRenderFns } from "./inputVAT.vue?vue&type=template&id=c2e630e8&scoped=true&"
import script from "./inputVAT.vue?vue&type=script&lang=ts&"
export * from "./inputVAT.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2e630e8",
  null
  
)

export default component.exports