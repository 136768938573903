/*
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2021-10-15 15:01:48
 * @LastEditors: liuxia
 * @LastEditTime: 2022-11-08 14:13:47
 */
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import * as api from '@/api/projectDetail';
interface resData {
  [key: string]: any;
}
export default Vue.extend({
  data() {
    return {
      tableHeadHeight: 0,
      page: 1,
      limit: 100,
      tableData: [] as any, // 表格数据
      loading: false, // 数据渲染前加载
      scrollSwitch: false, // 是否重新请求数据
      scrollTop: 0, // 滑动距离
      currentOffsetTop: 0,
      replaceFinish: false, // 是否替换完成
      isClickReplace: false, // 是否点击替换按钮
      invitationSequenceNbr: '', // 点击招标文件编码,相对应的查找出投标文件的sequenceNbr(方便查找相对应清单的定额)
      currentInfo: null,
    };
  },
  props: {
    componentHeight: {
      type: Number,
      default: 0,
    },
    xmType: {
      type: String,
      default: '',
    },
    itemCategory: {
      type: String,
      default: '',
    },
    fbSequenceNbr: {
      type: String,
      default: '',
    },
    itemList: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters([
      'currentTreeInfo',
      'projectBidSequenceNbr',
      'refreshTreeFrom',
      'checkedValue',
      'isThereReplace',
      'relevancy',
      'tenderRelevancy',
      'readOnly',
    ]),
    tableScroll() {
      const num: number = this.componentHeight - this.tableHeadHeight - 3;
      return { y: num, x: 500 };
    },
    componentsStyle(): { height: string; wordBreak: string } {
      return {
        height: this.componentHeight + 'px',
        wordBreak: 'break-all',
      };
    },
  },
  watch: {
    fbSequenceNbr: {
      handler: function (val, oldVal) {
        if (val === oldVal || !val) return '';
        if (val && this.checkedValue === '') {
          this.page = 1;
          if (val === '147852369874556321') return;
          this.branchPage(0);
          setTimeout(() => {
            this.moreLoad();
          });
        } else if (val && this.checkedValue !== '') {
          this.page = 1;
          this.unitItemList();
        }
        this.setTableHeadHeight();
      },
      immediate: true,
    },
    // refreshTreeFrom: function (val, oldVal) {
    //   console.log('9999999999999')
    //   if (!val || val === oldVal) return '';
    //   this.branchPage(0);
    // },
    checkedValue: function (val) {
      if (val !== '') {
        this.page = 1;
        this.unitItemList();
      } else {
        this.page = 1;
        this.branchPage(0);
      }
      this.moreLoad();
    },
    relevancy: function () {
      this.replaceFinish = false;
      if (this.isThereReplace && this.checkedValue !== '') {
        this.page = 1;
        this.unitItemList();
      }
    },
    itemList: function (val) {
      if (val) {
        this.loading = false;
        this.tableData = this.itemList;
      }
    },
  },
  mounted() {
    if (this.readOnly) {
      this.loading = true;
      // this.tableData = this.itemList;
      return;
    }
    if (this.xmType + this.itemCategory === '0702') {
      setTimeout(() => {
        this.branchPage(0);
        this.moreLoad();
      }, 500);
    }
    // if (
    //   this.fbSequenceNbr === '147852369874556321' ||
    //   this.xmType + this.itemCategory === '0701'
    // )
    //   return;
    // if (
    //   this.currentTreeInfo.bidSequenceNbr &&
    //   this.xmType &&
    //   this.checkedValue === ''
    // ) {
    //   setTimeout(() => {
    //     this.branchPage(0);
    //     this.moreLoad();
    //   }, 500);
    // } else if (
    //   this.currentTreeInfo.bidSequenceNbr &&
    //   this.xmType &&
    //   this.checkedValue
    // ) {
    //   setTimeout(() => {
    //     this.unitItemList();
    //   }, 500);
    // }
  },
  methods: {
    ...mapMutations(['SET_DISABLED_CHECKBOX']),
    moreLoad() {
      const tableEl = document.querySelector(
        '.tender-item-engineering .ant-table-body'
      ) as HTMLDivElement;
      tableEl.onscroll = () => {
        // 滚动条的高度 + 可视区域的高度 >= 滚动里面的总高度时，就说明滚动到底部了
        if (
          Math.ceil(tableEl.scrollTop + tableEl.clientHeight + 4) >=
          tableEl.scrollHeight
        ) {
          console.log('到底啦~', this);
          if (this.scrollSwitch && this.checkedValue === '') {
            this.scrollTop = tableEl.scrollTop;
            this.page++;
            this.branchPage(null);
          } else if (this.scrollSwitch && this.checkedValue !== '') {
            this.scrollTop = tableEl.scrollTop;
            this.page++;
            this.unitItemList();
          }
        }
      };
    },
    setTableHeadHeight() {
      setTimeout(() => {
        const tableEl = document.querySelector(
          '.ant-table-fixed'
        ) as HTMLElement;
        let tableHeadHeight = 0;
        if (tableEl) {
          tableHeadHeight = tableEl.clientHeight;
        }
        this.tableHeadHeight = tableHeadHeight;
      }, 500);
    },
    branchPage(type) {
      this.loading = true;
      const apiData = {
        page: this.page,
        limit: this.limit,
        xmType: this.xmType,
        itemCategory: this.itemCategory,
        fbSequenceNbr: this.fbSequenceNbr,
        unitSequenceNbr: this.currentTreeInfo.bidSequenceNbr,
        isNew: false,
        fromtype: '02',
        constructSequenceNbr: this.projectBidSequenceNbr,
        singleSequenceNbr: this.currentTreeInfo.bidParentId,
      };
      api
        .coccMtdsResource_1_project_branchsCategory(this.xmType, apiData)
        .then((res: resData) => {
          if (res.status === 200 && res.result) {
            this.getList(type, res);
          }
        })
        .catch((error) => {
          console.log('catch', error);
          this.loading = false;
        })
        .finally(() => {
          console.log('finally');
          this.loading = false;
        });
    },
    unitItemList(type = 0) {
      this.loading = true;
      const apiData = {
        unitSequenceNbr: this.currentTreeInfo.bidSequenceNbr,
        constructSequenceNbr: this.projectBidSequenceNbr,
        matchType: this.checkedValue,
        itemType: this.xmType === '01' ? 'fbfx' : 'djcs',
        page: this.page,
        limit: this.limit,
      };
      this.SET_DISABLED_CHECKBOX(true);
      api
        .unitItemList(apiData)
        .then((res: any) => {
          if (res.status === 200 && res.result) {
            this.getList(type, res);
          }
        })
        .finally(() => {
          this.SET_DISABLED_CHECKBOX(false);
        });
    },
    /**
     * 企业成本库更改数据跳转详情页，查找第一条清单信息
     */
    getFirstUpdateInfo() {
      const receiveResult = JSON.parse(
        sessionStorage.getItem('receiveResult') as string
      );
      if (receiveResult && receiveResult.tbQdSequenceNbr) {
        let bodyEl;
        let scrollTop = 0;
        this.tableData.forEach((item, index) => {
          if (item.sequenceNbr === receiveResult.tbQdSequenceNbr) {
            receiveResult.tbQdSequenceNbr = item.sequenceNbr;
            bodyEl = (this.$refs.table as any).$children[0].$children[0]
              .$children[0].$children[0].$children[0].$children[0].$children[1];
            this.$nextTick(() => {
              scrollTop =
                bodyEl.$children[0].$children[index + 1].$el.offsetTop;
              (
                document.querySelector(
                  '.tender-item-engineering .ant-table-body'
                ) as HTMLDivElement
              ).scrollTop = scrollTop;
              sessionStorage.removeItem('receiveResult');
            });
          }
        });
      }
    },
    getList(type, res) {
      this.loading = false;
      if (
        this.page === 1 &&
        !type &&
        (!this.relevancy || !this.tenderRelevancy)
      ) {
        (
          document.querySelector(
            '.tender-item-engineering .ant-table-body'
          ) as HTMLDivElement
        ).scrollTop = 0;
        this.tableData = [];
      }
      if (res.result.list.length === this.limit) {
        this.scrollSwitch = true;
      } else {
        this.scrollSwitch = false;
      }
      if (type === 1) {
        console.log('type', this.currentOffsetTop);
        setTimeout(() => {
          (
            document.querySelector(
              '.tender-item-engineering .ant-table-body'
            ) as HTMLDivElement
          ).scrollTop = this.currentOffsetTop;
        }, 500);
        this.tableData.splice(
          (this.page - 1) * this.limit,
          this.tableData.length
        );
        this.tableData = this.tableData.concat(res.result.list);
      } else if (type === 2) {
        setTimeout(() => {
          (
            document.querySelector(
              '.tender-item-engineering .ant-table-body'
            ) as HTMLDivElement
          ).scrollTop = this.currentOffsetTop;
        }, 500);
        const obj = res.result.list.filter(
          (x) => x.sequenceNbr === (this.currentInfo as any).sequenceNbr
        )[0];
        const target = this.tableData.filter(
          (item: any) =>
            (this.currentInfo as any).sequenceNbr === item.sequenceNbr
        )[0] as any;
        if (target) {
          target.highlightColor = obj.highlightColor;
        }
      } else {
        this.tableData = this.tableData.concat(res.result.list);
      }
      if (
        !(
          this.page === 1 &&
          !type &&
          (!this.relevancy || !this.tenderRelevancy)
        )
      ) {
        this.$nextTick(() => {
          (
            document.querySelector(
              '.tender-item-engineering .ant-table-body'
            ) as HTMLDivElement
          ).scrollTop = this.scrollTop;
        });
      }
      this.getFirstUpdateInfo();
      if (this.isClickReplace) {
        this.replaceFinish = true;
        this.isClickReplace = false;
      }
    },
    cancelReplace() {
      this.isClickReplace = true;
      // this.replaceFinish = true
      if (this.checkedValue) {
        this.unitItemList(1);
      } else {
        this.branchPage(1);
      }
    },
    updateReplace(val) {
      if (val) {
        if (this.checkedValue) {
          this.unitItemList();
        } else {
          this.branchPage(1);
        }
      }
    },
    rowClassName(record) {
      let className = 'parent-show';
      if (record.highlightColor === 1) {
        className = 'manual-adjustment';
      }
      if (
        this.replaceFinish &&
        ((record.fxCode && record.sequenceNbr === this.invitationSequenceNbr) ||
          (record.sequenceNbr && record.sequenceNbr === this.tenderRelevancy))
      ) {
        className = 'animationFlicker';
      }
      if (
        (this.replaceFinish &&
          this.relevancy &&
          record.parentId === this.invitationSequenceNbr) ||
        (this.tenderRelevancy && record.parentId === this.tenderRelevancy)
      ) {
        className = 'quotaAnimationFlicker';
      }
      if (!this.replaceFinish && record.kind === '03') className = 'child-show';
      if (record.fxCode && record.fxCode === this.relevancy)
        className = 'relevancy-show';
      return className;
    },
    updateColorStatus(record) {
      this.currentInfo = record;
      if (this.checkedValue) {
        this.unitItemList(2);
      } else {
        this.branchPage(2);
      }
    },
  },
});
